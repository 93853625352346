.cema-form .cema-form-input > .ui.label {
    font-size: 11px !important;
    width: 100px;
}

.cema-form .cema-form-input > .ui.checkbox > label {
  font-size: 11px !important;
}

.cema-form .ui.button, .cema-form .ui.buttons .button, .cema-form .ui.buttons .or, .cema-form-cancel.button {
  font-size: 12px !important;
}

.cema-form-field input.search {
  width: 100% !important;
}

/* .cema-form div.field {
  height: 35px !important;
} */

/* .cema-form div.field > div.cema-form-input {
  height: 35px !important;
} */

.cema-form .cema-form-field.error:not(.no-error) {
  position: relative !important;
  margin-bottom: 12px !important;
}

.cema-form .cema-form-input-error {
  position: absolute;
  top: 36px;
  left: 2px;
  color: red !important;
  font-size: 11px !important;
}

.info-container .info-segment-container {
    height: calc(100% - 40px);
  }
  
  @media (min-width: 1200px) {
    .copy-checkbox-container {
      width: 10%;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .copy-checkbox-container {
      width: 12%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    .copy-checkbox-container {
      width: 15%;
    }
  }
  
  @media (min-width: 600px) and (max-width: 767px) {
    .copy-checkbox-container {
      width: 18%;
    }
  }
  
  .copy-checkbox-container .column {
    text-align: center !important;
  }
  
  .copy-checkbox-container .row:first-child {
    padding-bottom: 0.5rem !important;
  }
  
  .copy-checkbox-container .row:last-child {
    padding-top: 0 !important;
  }

  .card-container .row:last-child {
      padding-bottom: 0rem !important;
  }
 
  .card-container .column {
      padding-bottom: 1rem !important;
  }

  .card-container .cema-card .cema-card-header {
    max-height: 47.15px !important;
  }
  
  .card-container .cema-card .cema-card-extra {
    padding: .5em 1em !important;
  }

  .card-container .cema-card.isEditing {
    box-shadow: 0 1px 3px 0 #f2711c, 0 0 0 1px #f2711c;
}

  .cover-page-section:last-child {
    padding-bottom: 1rem !important;
  }

  .cover-page-section .row:not(:last-child) {
    padding-bottom: 0 !important;
  }

  .cover-page-section .row:not(:first-child):not(.cover-page-consolidation-row)  {
    padding-top: 0.35rem !important;
  }

  .cover-page-section .cover-page-list .ui.checkbox {
    min-height: 11px !important;
    font-size: 0.25rem !important;
  }

  .cover-page-section .cover-page-list .ui.checkbox .box:before,
  .cover-page-section .cover-page-list .ui.checkbox label:before
   {
     width: 13px !important;
     height: 13px !important;
   }

   .cover-page-section .cover-page-list .ui.checkbox .box:after,
  .cover-page-section .cover-page-list .ui.checkbox label:after
   {
     width: 13px !important;
     height: 13px !important;
     font-size: 12px !important;
   }

   .cover-page-section .cover-page-list .ui.checkbox.cb-scan .box:before,
  .cover-page-section .cover-page-list .ui.checkbox.cb-scan label:before
   {
     border-color: blue;
   }

   .cover-page-section .cover-page-list .ui.checkbox.cb-original .box:before,
  .cover-page-section .cover-page-list .ui.checkbox.cb-original label:before
   {
     border-color: green;
   }

   .cover-page-section .cover-page-list .ui.checkbox.cb-scan .box:after,
   .cover-page-section .cover-page-list .ui.checkbox.cb-scan label:after
    {
      color: blue;
    }
 
    .cover-page-section .cover-page-list .ui.checkbox.cb-original .box:after,
   .cover-page-section .cover-page-list .ui.checkbox.cb-original label:after
    {
      color: green;
    }

  .cover-page-section .cover-page-list .cover-page-list-allonges-checkboxes {
    padding-left: 0 !important;
    padding-top: 0.25em !important;
    padding-bottom: 0!important;
  }

  .ui.grid.cover-page-section+.grid {
    margin-top: 0.5rem;
  }

  .ui.bulleted.list .list.cover-page-list-allonges, ul.ui.list ul.cover-page-list-allonges {
    padding-top: 0.25em !important;
    padding-bottom: 0!important;
  }

  .ui.selection.dropdown .menu>.item {
    font-size: inherit;
  }

  .info-container > .row:first-child {
    padding-bottom: 0 !important;
  }

  .info-container .info-title {
    padding-bottom: 0 !important;
    /* font-size: 1.14285714rem !important; */
  }

  .info-container .info-content {
    clear: both;
    padding: 0 !important;
  }

  .info-container .info-content > div.ui.segments {
    border: 0;
    box-shadow: none;
    margin-top: 0;
    margin-left: 9px;
  }

  .info-container .info-content div.ui.horizontal.segments>.segment {
    border-left: 0 !important;
    padding-right: 0 !important;
    margin: auto;
  }

  .info-container .info-content div.ui.segments>.horizontal.segments {
    border-top: 0 !important;
    padding-bottom: 4px !important;
    min-height: 34px;
  }

  .info-container .info-content div.ui.horizontal.segments>.segment:first-child {
    max-width:150px;
    width:150px;
  }

  .info-container .info-content > div.ui.horizontal.segments>.segment > *:not(:first-child) {
    margin-left: 8px;
  }

  .info-container .ui.segment {
    padding: 0 1em !important;
  }

  .info-container > .row:last-child {
    padding-top: 0 !important;
  }

  .info-container .info-content .ui.mini.input {
    max-width: 10em;
  }

  .info-container .info-content .ui.mini.input > * {
    min-width: inherit;
  }

  /* .ui.search>.results .result {
    font-size: 0.49em !important;
  } */

  .ui.mini.search.info-search input, .ui.small.search.info-search input {
    border:none;
    padding: 0 1em 0 1.5em !important;
  }

.attorney-search>.ui.icon.input>input {
    border-radius: 0 !important;
    padding-right: 0 !important;
  }

  .ui.mini.search.info-search i.icon, .ui.small.search.info-search i.icon {
    width: 0.671429em;
  }

  .ui.mini.search>div, .ui.search.search>div {
    width: 100% !important;
  }

  .info-container .info-title .info-search {
    padding-top: 0px;
    padding-right: 0px;
    /* margin-left: 20%; */
    width: 50%;
  }

  .info-container .info-content {
    margin-top: 4px;
  }

  .info-container .info-content > .ui.segments {
    margin-left: -14px !important;
  }

  .info-container .info-title em, .info-container .info-content .info-sub-title em {
    font-size: 15px !important;
  }

  .info-container .info-content label, .info-container .info-content em {
    font-size: 12.5714px !important;
  }

  .ui.form textarea:not([rows]) {
    max-height: 48em !important;
  }

  .attorney-info-container .attorney-address > p:not(:last-child) {
    margin-bottom: 0 !important;
  }

  div.contact-bank-cell {
    display: flex !important;
    flex-direction: column !important;
    overflow-y: auto !important;
    max-height: 100% !important;
    padding: 0.5em 0 !important;
  }

  div.contact-cell {
    overflow-x: hidden;
    overflow-y: auto !important;
    max-height: 100% !important;
    width: 100%;
    padding: 0.5em 0 !important;
  }