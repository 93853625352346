.table-dashboard.ui.table {
    border: none !important;
  }
  
  .table-dashboard.ui.table tr {
    height: 34px;
    cursor: pointer;
  }

  .Dashboard .ui.right.rail {
      margin: 0 2rem 0 1rem;
      padding: 0;
  }

  .Dashboard .ui.rail {
      width: 175px;
  }

  .Dashboard .rdt_Table .rdt_TableBody {
    overflow-y: auto;
  }