html, body {
  height: inherit !important;
  /* font-size: 13px; */
}

/* @media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}  */

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.no-border {
  border: 0 !important;
  box-shadow: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-v {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-padding-h {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hide-me {
  display: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
}

/* .ui.modal:not(.compact) {
  width: 50% !important;
  margin-left: 25% !important;
  height: 70%!important;
  margin-top: 5% !important;
  } */

.navigation-lightblue {
  background: rgb(106, 174, 219) !important;
}

.lightblue {
  background: lightblue !important;
}

.cema-status-label {
  color: white !important;
}

.cema-not-ordered-color {
  background: red !important;
}
.cema-ordered-color {
  background: #FF781D !important;
}
.cema-scans-receive-color {
  background: #FFFF00 !important;
  color: black !important;
}
.cema-partial-receive-color {
  background: #FFFF00 !important;
  color: black !important;
}
.cema-partial-submit-color {
  background: linear-gradient(170deg, #FFFF00 50%, #5D9CD7 50%) !important;
  color: black !important;
}
.cema-submit-color {
  background: #5D9CD7 !important;
}
.cema-approve-color {
  background: #21BA45 !important;
}
.cema-closed-color {
  background: black !important;
}

.cema-archived-color {
  background: grey !important;
}